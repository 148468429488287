<template>
  <CDataTable
      hover
      striped
      :items="Array.isArray(users) ? users : Array(users)"
      :fields="fieldsProviderUsersDetails"
      :items-per-page="perPage"
      :pagination="paginationProps"
      @row-clicked="rowClicked"
      index-column
      :clickable-rows="clickableRows"
  >
    <template #email="data">
      <td>
        {{ data.item.email }}
      </td>
    </template>
    <template #name="data">
      <td>
        {{ data.item.first_name }} {{ data.item.last_name }}
      </td>
    </template>
    <template #phones="data">
      <td>
        <ul style="list-style-type: none" class="p-0">
          <template v-if="data.item.phones.length">
            <li v-for="tel in isPhones(data.item.phones)" :key="tel">
              <a :href="'tel://'+tel">
                {{ tel }}
              </a>
            </li>
          </template>
          <template v-else>***</template>
        </ul>
      </td>
    </template>
    <template #notification="data">
      <td>
        <CBadge class="p-1" :color="getBadge(data.item.notification)">
          {{ data.item.notification ? "Включено" : "Вимкнено" }}
        </CBadge>
      </td>
    </template>
    <template #role="data">
      <td>
        <CBadge class="p-1" :color="getBadgeRole(data.item.role)">
          {{ data.item.role }}
        </CBadge>
      </td>
    </template>

  </CDataTable>
</template>
<script lang="js">
import {fieldsProviderUsersDetails, paginationProps} from './Fields';

export default {
  emits:['rowClicked'],
  props: {
    users: {
      required: true,
      type: [Array, Object],
      default: null
    },
    perPage: {
      type: Number,
      required: false,
      default: 5
    },
    clickableRows: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      fieldsProviderUsersDetails,
      paginationProps
    }
  },
  methods: {
    getBadge(notification) {
      return notification ? 'success' : !notification ? 'secondary' : 'primary';
    },
    getBadgeRole(role) {
      return role === 'Адмін' ? 'info'
          : role === 'Користувач' ? 'warning' : 'primary'
    },
    rowClicked(item, index) {
      let user = item;
      user.phones = this.isPhones(user.phones);
      this.$emit('rowClicked', user);
    },
    isPhones(phones){
      return String(phones).length ? String(phones).split(',') : [];
    }
  }
}
</script>
