<template>
    <CModal
            color="warning"
            size="lg"
            :title="title"
            :show.sync="showCreateUser"
            addContentClasses="srl"
    >
        <template #body-wrapper>
            <div class="p-2">
                <CAlert v-if="success" show color="success">Користувача створено, йому на пошту відправлено лист
                    підтвердження
                </CAlert>
                <CAlert v-if="failed" show color="danger">Щось пішло не так... Спробуйте пізніше</CAlert>
                <CAlert v-if="removed" show color="success">Ура! Користувач видалений!</CAlert>
                <div class="form-item" :class="{'errorInput': $v.user.email.$error}">
                    <CInput
                            placeholder="Email"
                            autocomplete="email"
                            required
                            v-model="user.email"
                            invalid-feedback="Введено некоректні дані"
                            @change="$v.user.email.$touch()"
                            :class=" {
                      'error': $v.user.email.$error
                    }"
                    >
                        <template #prepend-content>
                            <CIcon name="cil-envelope-closed"/>
                        </template>
                    </CInput>
                    <small v-if="!$v.user.email.required" class="form-text errorText w-100">Необхідно заповнити
                        E-mail.</small>
                    <small v-if="!$v.user.email.email" class="form-text errorText w-100">E-mail вказвно не
                        коректно.</small>
                    <small v-if="!$v.user.email.maxLength" class="form-text errorText w-100">Максимальна кількість
                        символів
                        {{ $v.user.email.$params.maxLength.max }}.</small>
                </div>
                <div class="form-item" :class="{'errorInput': $v.user.first_name.$error}">
                    <CInput
                            placeholder="Ім'я"
                            autocomplete="first_name"
                            required
                            :disabled="removeUser"
                            v-model="user.first_name"
                            @change="$v.user.first_name.$touch()"
                            :class="{'error': $v.user.first_name.$error}"
                    >
                        <template #prepend-content>
                            <CIcon name="cil-user"/>
                        </template>
                    </CInput>
                    <small v-if="!$v.user.first_name.required" class="form-text errorText w-100">Необхідно заповнити
                        поле.</small>
                    <small v-if="!$v.user.first_name.maxLength" class="form-text errorText w-100">Максимальна кількість
                        символів
                        {{ $v.user.first_name.$params.maxLength.max }}.</small>
                </div>
                <div class="form-item" :class="{'errorInput': $v.user.last_name.$error}">
                    <CInput
                            placeholder="Прізвище"
                            autocomplete="last_name"
                            required
                            :disabled="removeUser"
                            v-model="user.last_name"
                            @change="$v.user.last_name.$touch()"
                            :class="{'error': $v.user.last_name.$error}"
                    >
                        <template #prepend-content>
                            <CIcon name="cil-user"/>
                        </template>
                    </CInput>
                    <small v-if="!$v.user.last_name.required" class="form-text errorText w-100">Необхідно заповнити
                        поле.</small>
                    <small v-if="!$v.user.last_name.maxLength" class="form-text errorText w-100">Максимальна кількість
                        символів
                        {{ $v.user.last_name.$params.maxLength.max }}.</small>
                </div>
                <CRow v-for="(phone, index) in phones" :key="index">
                    <div class="form-item w-100" :class="{'errorInput': $v.phones.$each[index].$error}">
                        <CCol class="text-left">
                            <CInput
                                    placeholder="Телефон"
                                    :value="phone"
                                    @input="(val) => saveValue(index, val)"
                                    required
                                    v-mask="[ '+38 (###) ###-##-##']"
                                    invalid-feedback="Потрібно ввести 12 цифр"
                            >
                                <template #prepend-content>
                                    <CIcon name="cil-phone"/>
                                </template>
                                <template v-if="phones.length > 1" #append>
                                    <CButton color="danger" @click="removeElement(index)">Видалити</CButton>
                                </template>
                            </CInput>
                        </CCol>
                        <small v-if="!$v.phones.$each[index].required ||
              !$v.phones.$each[index].$params.maxLength ||
              !$v.phones.$each[index].$params.minLength" class="form-text errorText w-100 ml-3">Необхідно
                            заповнити номер телефона або його видалити.</small>
                    </div>
                </CRow>
                <CRow class="align-items-center">
                    <CCol col="12" sm="12" md="12" lg="12" xl="12" class="mb-3 mb-xl-3 text-right">
                        <CButton color="warning" class="text-white" size="sm" @click="addNumber()">Додати номер
                        </CButton>
                    </CCol>
                </CRow>
                <CSelect
                        label="Оповіщення"
                        :options="['Включено', 'Вимкнено']"
                        :value.sync="notification"
                        @change="changeNotification"
                />
                <CSelect
                        label="Права доступу"
                        :options="['Адмін', 'Користувач']"
                        :value.sync="user.role"
                />
            </div>
        </template>
        <template #footer>
            <CRow class="w-100">
                <CCol class="text-left">
                    <CButton
                            v-if="removeUser"
                            size="sm"
                            color="danger"
                            @click="removeUserFunc">Видалити
                    </CButton>
                </CCol>
                <CCol class="text-right justify-between">
                    <CButton size="sm" color="secondary" class="m-1" @click="closed">Скасувати</CButton>
                    <CButton size="sm" color="warning" class="m-1" @click="validation">{{ btnText }}</CButton>
                </CCol>
            </CRow>
        </template>
    </CModal>
</template>

<script>
import {mask} from 'vue-the-mask'
import {required, email, maxLength, requiredIf, minLength} from 'vuelidate/lib/validators'

export default {
    name: "ModalCreateProvideUser",
    directives: {mask},
    props: {
        showCreateUser: {
            type: Boolean,
            required: true,
            default: false
        },
        providerID: {
            type: Number,
            required: true,
            default: 0
        },
        title: {
            type: String,
            required: false,
            default: 'Створення користувача'
        },
        changesUser: {
            type: Object,
            required: false,
            default: undefined
        },
        funcAddUser: {
            type: Function,
            required: true
        },
        funcRemoveUser: {
            type: Function,
            required: false
        }
    },
    data() {
        return {
            failed: false,
            success: false,
            removed: false,
            user: {
                first_name: "",
                last_name: "",
                notification: true,
                email: "",
                role: "Користувач"
            },
            phones: [],
            loading: false,
            notification: "Включено",
        }
    },
    validations: {
        user: {
            first_name: {required, type: String, maxLength: maxLength(80)},
            last_name: {required, type: String, maxLength: maxLength(80)},
            notification: {required},
            email: {required, email, maxLength: maxLength(350)},
            role: {required, type: String, maxLength: maxLength(50)},
            id: {
                required: requiredIf(function () {
                    return !!this.$props?.changesUser;
                })
            }
        },
        phones: {
            $each: {
                required: requiredIf((val) => val),
                minLength: minLength(19),
                type: String,
                maxLength: maxLength(19)
            }
        }
    },
    watch: {
        showCreateUser(val) {
            if (!val) return this.closed();
            if (!!this.$props?.changesUser && Object.keys(this.$props?.changesUser).length) {
                let {phones, created_at, updated_at, ...userData} = {...this.$props.changesUser};
                Object.assign(this.user, userData);
                this.phones = [...phones];
            }
        }
    },
    computed:{
        btnText() {
            return typeof this.$props.changesUser === 'undefined' ? 'Додати користувача' :
              typeof this.$props.changesUser === 'object' ? 'Редагувати користувача' : '';
        },
        removeUser() {
            return typeof this.$props.changesUser === 'object';
        },
    },
    methods: {
        changeNotification() {
            if (this.notification === "Включено") {
                this.user.notification = true;
            }
            if (this.notification === "Вимкнено") {
                this.user.notification = false;
            }
        },
        addNumber() {
            this.phones.push('');
        },
        removeElement(index) {
            this.phones.splice(index, 1)
        },
        saveValue(index, value) {
            this.$set(this.phones, index, value)
        },
        validation() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.addUser()
            }
        },
        addUser() {
            let phones = this.phones.join(',');
            const isUser = {
                email: this.user.email,
                first_name: this.user.first_name,
                last_name: this.user.last_name,
                phones: phones,
                notification: this.user.notification,
                role: this.user.role,
                provider_id: this.$props.providerID
            }
            if (!!this.$props.changesUser && this.user?.id) {
                isUser.id = this.user.id;
            }
            this.$props.funcAddUser(isUser, (e) => this.statusMessage(e));
        },
        removeUserFunc() {
            const isUser = {
                provider_id: this.$props.providerID,
                id: this.user.id
            }

            this.$props.funcRemoveUser(isUser, (e) => this.statusMessage(e));
        },
        clearing() {
            this.user = {
                first_name: "",
                last_name: "",
                notification: true,
                email: "",
                role: "Користувач"
            }
            this.phones = [];
            this.$v.$reset();
        },
        closed() {
            this.clearing();
            this.$emit('closing')
        },
        statusMessage(status) {
            if (status === 'added') {
                this.success = true;
                setTimeout(() => {
                    this.success = false;
                    this.$emit('updateValue')
                    this.closed();
                }, 900)
            }
            if (status === 'failed') {
                this.failed = true;
                setTimeout(() => {
                    this.failed = false;
                    this.closed();
                }, 900)
            }
            if (status === 'removed') {
                this.removed = true;
                setTimeout(() => {
                    this.removed = false;
                    this.closed();
                }, 900)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.form-item .errorText {
  display: none;
  //font-size: 1rem !important;
  color: #cb203d;
}

.form-item {
  &.errorInput .errorText {
    display: block;
  }
}

.error {
  border-color: #ff002a !important;
  border-style: solid !important;
  border-width: 2px !important;
  border-radius: .3rem;
}
</style>
